require('../css/app.scss');


const $ = require('jquery');
//global jQuery
global.$ = global.jQuery = $;
require('bootstrap');

var greet = require('./greet');

 $(document).ready(function() {
     $('.post-card').hover(function() {
         $(this).find('.description').stop().animate({
             height: "toggle",
             opacity: "toggle"
         }, 300);
     });

     $('.product-card').hover(function() {
         $(this).find('.description').stop().animate({
             height: "toggle",
             opacity: "toggle"
         }, 300);
     });



     // $('.plopy').hover(function () {
     //     console.log("alors");
     // });



    // $('.welcomeMsg').prepend('<h1>'+greet('jill')+'</h1>');


 });
console.log('Hello Webpack Encore');